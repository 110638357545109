<template>
    <div v-if="item" :class="item.ContainerClass">
        <div class="flex justify-between">
            <div v-if="item.Left" class="flex" :class="transition ? 'text-' + item.ColorTransition : ''">
                <logo v-if="$route.path === '/home'" :data="item.Left" :transition="transition" />
                <menu-component :data="item.Left.Menu" v-if="windowWidth > 600" />
            </div>
            <div v-if="item.Right && windowWidth > 600" class="flex" :class="transition ? 'text-' + item.ColorTransition : ''">
                <menu-component :data="item.Right.Menu" />
            </div>
            <div class="flex" v-if="windowWidth < 600">
                <div class="flex transform rotate-180" @click="$sidebar.show = true" :class="transition ? 'text-white' : ''">
                    <b-icon icon="menu" custom-size="mdi-24px"></b-icon>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Logo: () => import('./logo'),
        MenuComponent: () => import('./menu')
    },
    props: {
        item: {
            type: Object
        },
        transition: {
            type: Boolean
        }
    },
    methods: {
        backStep () {
            this.$router.push('/booking')
        }
    }
}
</script>
